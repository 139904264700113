import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"
import { useTranslation } from "react-i18next"
import Rating from "@material-ui/lab/Rating"
import { SnackbarProvider } from "notistack"
import MuiAlert from "@material-ui/lab/Alert"

import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Snackbar,
} from "@material-ui/core"

import SigninForm from "../components/signin-form"

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import BookImage from "../../static/images/book-image.png"

const windowGlobal = typeof window !== "undefined" && window

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  bookItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      maxWidth: 175,
      textAlign: "center",
      margin: "0 auto",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      border: "3px solid #000",
      borderRadius: 10,
    },
    "& p": {
      margin: "0 auto",
      fontSize: "1.2rem",
      color: "#003e96",
    },
    "& .add-to-cart-btn": {
      display: "none",
    },
    "& .rating-and-price": {
      marginTop: 1
    },
    "&:hover .rating-and-price": {
      display: "none",
    },
    "&:hover .add-to-cart-btn": {
      display: "block",
    },
  },
  bookDetails: {
    color: "#092b5b !important",
    fontSize: "1rem !important",
  },
  addToCartBtn: {
    color: "#003e96",
    borderRadius: 10,
    marginTop: "1.1rem",
  },
  addToCartBtnIcon: {
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "1.5rem",
  },
  bookImage: {
    width:  176,
    height: 244,
    float: "center",
    marginBottom: 12,
    objectFit: "cover",
  },
}))

const FavBooks = ({ book, openSnackbar }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const [signFormOpen, setSignFormOpen] = useState(false)
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [countItem] = useState({ count: 1 })

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }
  let bookItems = book.types !== 0 ? book.types.filter(item => item.type === "paper") : ""
  let bookItem = bookItems.length > 0 ? bookItems[0] : {}
  const { type, price } = bookItem

  let bookPrice = price > 0 ? price : ""

  const signFormClose = () => {
    setSignFormOpen(false)
  }
  const handleOpenSnackbar = () => {
    openSnackbar()
  }

  const handleCloseSnackBar = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
  }

  const handleAddToCard = () => {
    if (jwtForProfile) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/add-cart-item`,
          {
            quantity: countItem.count,
            book: book.strapiId ? book.strapiId : book.id,
            format: "paper",
          },
          { headers: { Authorization: `Bearer ${jwtForProfile}` } }
        )
        .then(() => {
          handleOpenSnackbar()
        })
    } else {
      setSignFormOpen(true)
    }
  }
  
  const handleDeleteFavBook = () => {
    axios
      .post(
        `${process.env.GATSBY_API_URL}/delFavBook`,
        {
          id: book.id,
        },
        { headers: { Authorization: `Bearer ${jwtForProfile}` } }
      )
      .then(() => {
        setOpenAlertSuccess(true)
        setTimeout(() => {
          setOpenAlertSuccess(false)
          windowGlobal.location.reload()
        }, 2000)
      })
  }

  return (
    <>
      <SigninForm isOpen={signFormOpen} onClose={signFormClose} />
      <Grid item xs={12} sm={6} lg={3} md={4} className={classes.bookItem}>
        <Link to={`/${i18n.language}/books/${book.id}`}>
          <img
            src={
              book.image ? `${process.env.GATSBY_API_URL}` + book.image.url : BookImage
            }
            alt={book.title}
            className={classes.bookImage}
          />
          <Typography variant="h5" component="p">
            {book.title}
          </Typography>
        </Link>
        <div className="rating-and-price">
          {book.rating && (
            <Rating
              name="half-rating-read"
              defaultValue={book.rating}
              precision={0.5}
              readOnly
            />
          )}
          <Typography className={classes.bookDetails}>
            {type && t("singleBook.type")}
            {bookPrice &&
              t("singleBook.price") +
                " " +
                parseFloat(bookPrice * currencyRateState).toFixed(2)}
            {bookPrice && (currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>)}
          </Typography>
        </div>
        <div className="add-to-cart-btn">
          <Button
            variant="contained"
            color="default"
            className={classes.addToCartBtn}
            onClick={handleAddToCard}
            startIcon={
              <ShoppingCartIcon className={classes.addToCartBtnIcon} />
            }
          >
            {t("singleBook.addToCart")}
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.addToCartBtn}
            onClick={handleDeleteFavBook}
            // startIcon={
            //   <ShoppingCartIcon className={classes.addToCartBtnIcon} />
            // }
          >
            {t("book.deleteFavBook")}
          </Button>
        </div>
      </Grid>
      <SnackbarProvider maxSnack={3}>
          <Snackbar
            open={openAlertSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
          >
            <Alert onClose={handleCloseSnackBar} severity="success">
              {t("book.deletedfromFavorite")}
            </Alert>
          </Snackbar>
        </SnackbarProvider>
    </>
  )
}
export default FavBooks
