import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import SingleBook from "./single-fav-book"
import MuiAlert from "@material-ui/lab/Alert"

import {
  makeStyles,
  Container,
  Grid,
  Box,
  Button,
  Snackbar,
} from "@material-ui/core"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loadmoreBtnWrap: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
}))

export default function SingleFavBook({ props, books }) {
  const classes = useStyles()
  const [t] = useTranslation()
  const [loadedBooks, setLoadedProperties] = React.useState(12)
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)

  const handleOpenSnackbar = () => {
    setOpenSuccessSnackbar(true)
    setTimeout(() => {
      setOpenSuccessSnackbar(false)
    }, 3000)
  }

  const handleCloseAddToCard = () => {
    setOpenSuccessSnackbar(false)
  }

  const handleLoadMore = () => {
    setLoadedProperties(loadedBooks + 8)
  }

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <Grid container spacing={4}>
        {books.slice(0, loadedBooks).map((item, key) => (
          <SingleBook
            book={item}
            key={key}
            openSnackbar={handleOpenSnackbar}
          />
        ))}
      </Grid>
      {books.length > loadedBooks && (
        <Box className={classes.loadmoreBtnWrap} p={2}>
          <Button variant="outlined" color="primary" onClick={handleLoadMore}>
            {t("bookList.loadMoreBtn")}
          </Button>
        </Box>
      )}
      <Snackbar
        open={openSuccessSnackbar}
      >
        <Alert onClose={handleCloseAddToCard} severity="success">
          {t("book.itemAddedToCart")}
        </Alert>
      </Snackbar>
    </Container>
  )
}
