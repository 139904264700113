import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { SnackbarProvider } from "notistack"
import { useTranslation } from "react-i18next"

import {
  Button,
  makeStyles,
  Grid,
  TextField,
  FormControl,
  Snackbar,
  Typography,
  Box,
  Container,
  Tab,
  Tabs,
  Paper,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  AppBar,
} from "@material-ui/core"

import SEO from "../components/seo"
import Sigin from "../components/signin"
import MuiAlert from "@material-ui/lab/Alert"
import FavBookList from "../components/fav-book-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"
import SingleOrder from "../components/order"
import UserEditInfos from "../components/userEditInfos"

import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import UserImage from "../../static/images/user-picture.png"
import Transfer from "../../static/images/Icons/transfer.svg"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import WesternUnion from "../../static/images/Icons/western-union.svg"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function ButtonLink(props) {
  const { icon, primary, to } = props
  const classes = useStyles()

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <Button
      component={renderLink}
      className={`${classes.headerMenu} ${classes.headerMenuBtnsHover} ${classes.headerBtn}`}
      startIcon={icon}
    >
      {primary}
    </Button>
  )
}

ButtonLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerBtn: {
    color: "#000",
    fontSize: "1rem",
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "13px 12px",
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& input#userName:hover": {
      cursor: "not-allowed",
    },
  },
  form: {
    "& .react-tel-input": {
      marginBottom: 15,
      direction: "ltr",
      flip: false,
      "& input": {
        width: "100%",
      },
      "& .react-tel-input .form-control": {
        width: "100% important",
      },
    },
  },
  profileGridContainer: {
    "&:first-child": {
      alignItems: "stretch",
    },
    "&:last-child": {
      alignItems: "initial",
    },
  },
  sideBarGrid: {
    backgroundColor: "#f5f5f5",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      "&:after": {
        content: "''",
        backgroundImage: "linear-gradient(180deg, #f5f5f5, transparent)",
        position: "absolute",
        left: 0,
        bottom: "-50px",
        height: 50,
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      paddingBottom: "2rem",
      "&:after": {
        content: "",
        display: "none",
      },
    },
  },
  contentGrid: {
    // backgroundImage: `url(${MainBg})`,
    backgroundColor: "#fff",
    paddingTop: theme.spacing(5),
    paddingBottom: "7rem",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "3rem",
    },
  },
  myProfileTabs: {
    marginTop: "2rem",
    "& .MuiButtonBase-root span": {
      color: "#707070",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .Mui-selected.MuiButtonBase-root span": {
      color: "#003e96",
    },
    "& .MuiTabs-flexContainer + span.MuiTabs-indicator": {
      backgroundColor: "#003e96",
    },
  },
  subscriptionPackagesPaper: {
    borderRadius: 10,
    margin: 6,
    padding: 6,
    textAlign: "center",
    borderWidth: 3,
    borderStyle: "solid",
  },
  subscriptionPackagesGrid: {
    "&>div .MuiPaper-root": {
      borderColor: "#93278f",
    },
    "&>div:first-child .MuiPaper-root": {
      borderColor: "#f7931e",
    },
    "&>div:last-child .MuiPaper-root": {
      borderColor: "#ed1c24",
    },
    "&>div .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#93278f",
      fontSize: 11,
      color: "#fff",
    },
    "&>div:first-child .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#f7931e",
    },
    "&>div:last-child .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#ed1c24",
    },
  },
  tableGrid: {
    "& #vertical-tabpanel-1 > .MuiBox-root": {
      padding: 0,
    },
  },
  subscriptionCurrentPackagesPaper: {
    borderRadius: 10,
    margin: 6,
    padding: 6,
    textAlign: "center",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#8cc63f",
  },
  currentPackageTextGreen: {
    color: "#8cc63f",
    fontSize: 18,
  },
  currentPackageTextBlue: {
    color: "#003e96",
    fontWeight: 600,
    fontSize: 18,
  },
  trackingSliderTab: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 26,
    },
    "& .MuiBox-root, .MuiTypography-root, .MuiTypography-root:second-child": {
      width: "100%",
    },
  },
  myProfileSecondTabs: {
    marginTop: 0,
    marginBottom: "2rem",
  },
  orderIdBtn: {
    textDecoration: "underline",
  },
  deleteOrderIdBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  packageDetailsPrice: {
    direction: "rtl",
  },
  odrerIdAccordinDetailsMobile: {
    display: "inline-block",
    maxWidth: "100%",
  },
  orderIdBtnMobile: {
    display: "block",
  },
  profileImage: {
    outlineWidth: 0,
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
    },
    "& img": {
      maxWidth: 175,
      height: "auto",
      textAlign: "center",
      borderRadius: 10,
    },
  },
  paymentTypesTabs: {
    minWidth: 100,
    "&.Mui-selected": {
      backgroundColor: "#ccc",
    },
    "& .MuiTabScrollButton-root": {
      display: "none",
    },
  },
  paymentTypeTabsIcon: {
    fontSize: 32,
    width: 32,
    height: 32,
  },
  tabsAppBar: {
    marginTop: 40,
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabpanelStyle: {
    "& > div": {
      padding: "10px 20px ",
    },
  },
}))

const MyProfile = ({ pageContext }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { pLang, paymentOptions } = pageContext
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const phoneRegexd = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{4})$/
  const imageRef = useRef(null)
  const validateForm = useRef(null)

  const [orders, setOrders] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [packageId, setPackageId] = useState()
  const [favBooks, setFavBooks] = useState({})
  const [subscription, setSubscription] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [payType, setPayType] = useState("credit_card")
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [paymentTabValues, setPaymentTabValues] = useState(0)
  const [orderStatusState, setOrderStatusState] = useState("")
  const [orderIdForDetails, setOrderIdForDetails] = useState()
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [subscriptionValue, setGetSubscriptionValue] = useState([])
  const [subscriptionPackages, setGetSubscriptionPackages] = useState([])
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false)
  const [openAlertSuccessSubscription, setOpenAlertSuccessSubscription] = useState(false)
  const [errorMassege, setErrorMassege] = useState({
    username: false,
    usernameText: "",
    userNameModal: false,
    userNameModalTest: "",
    emailAddress: false,
    emailAddressText: "",
    cardEndYear: false,
    cardEndYearText: "",
    cardEndMonth: false,
    cardEndMonthText: "",
    cardCVV: false,
    cardCVVText: "",
    cardNumber: false,
    cardNumberText: "",
    cardName: false,
    cardNameText: "",
  })
  const [userValues, setUserValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    profileImage: "",
    subscription: "",
  })
  const [checkoutForm, setCheckoutForm] = useState({
    userNameModal: "",
    phoneNumber: "",
    emailAddress: "",
    cardName: "",
    cardNumber: "",
    cardCVV: "",
    cardEndMonth: "",
    cardEndYear: "",
  })

  const breadcrumbs = [
    {
      text: t("myProfile.myProfile"),
    },
  ]
  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, "my-profile", `/${i18n.language}/my-profile`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, "my-profile", `/${i18n.language}/my-profile`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/my-profile`
      }
    })
  }, [i18n, pLang])

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    // setOpenAlertError(false)
    setOpenAlertErrorOnSubmit(false)
    setOpenAlertSuccessSubscription(false)
  }

  const handleChangePaymentTabs = (event, newValue) => {
    setPaymentTabValues(newValue)
    setPayType(
      newValue === 0
        ? "credit_card"
        : newValue === 1
        ? "western_union"
        : newValue === 2
        ? "eft"
        : newValue === 3 && "whatsapp"
    )
  }

  const handleChangeModalForm = prop => event => {
    if (prop === "emailAddress" && isSubmitted) {
      enterdEmailAddress()
    } else if (prop === "phoneNumber") {
      enterdPhoneNumber()
    }
    setCheckoutForm({ ...checkoutForm, [prop]: event.target.value })
  }
  const handleClickOpenSubscriptionDialog = () => {
    setOpenSubscriptionDialog(true)
  }
  const handleCloseSubscriptionDialog = () => {
    setOpenSubscriptionDialog(false)
  }
  const enterdPhoneNumber = () => {
    if (phoneRegexd.test(checkoutForm.phoneNumber)) {
      setErrorMassege({
        ...errorMassege,
        phoneNumber: false,
        phoneNumberText: "",
      })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        phoneNumber: true,
        phoneNumberText: t("errors.invalidPhoneNumber"),
      })
      return false
    }
  }
  const enterdEmailAddress = () => {
    if (emailRegExp.test(checkoutForm.emailAddress)) {
      setErrorMassege({
        ...errorMassege,
        emailAddress: false,
        emailAddressText: "",
      })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        emailAddress: true,
        emailAddressText: t("errors.invalidEmailAddress"),
      })
      return false
    }
  }

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue)
    setGetSubscriptionValue(newValue)
  }
  let jwtForProfile
  let idForProfile
  let roleForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
    idForProfile = windowGlobal.sessionStorage.getItem("userId")
    roleForProfile = windowGlobal.sessionStorage.getItem("userRl")
  }

  const signout = () => {
    windowGlobal.sessionStorage.removeItem("jwtCode")
    windowGlobal.sessionStorage.removeItem("userId")
    windowGlobal.sessionStorage.removeItem("userRl")
    navigate(`/`)
  }
  const goToAdmin = () => {
    navigate(`/${i18n.language}/admin`)
  }
  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${jwtForProfile}` },
        })
        .then(res => {
          const data = res.data
          setUserValues({
            userName: data.username,
            lastName: data.lastName,
            firstName: data.firstName,
            profileImage: data.avatar,
            subscription: data.subscription,
          })
        })
    }
  }, [jwtForProfile])
  let formData
  if (windowGlobal) {
    formData = new FormData()
    formData.append("refId", idForProfile)
    formData.append("ref", "user")
    formData.append("field", "avatar")
    formData.append("source", "users-permissions")
    formData.append("files", imageRef.current && imageRef.current.files[0])
  }

  useEffect(() => {
    if (userValues.subscription !== null) {
      axios
        .get(`${process.env.GATSBY_API_URL}/get-subscription`, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          setSubscription(res.data)
        })
    }
    axios
      .get(`${process.env.GATSBY_API_URL}/subscription-packages`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
        },
      })
      .then(res => {
        setGetSubscriptionPackages(res.data)
      })
  }, [subscriptionValue, jwtForProfile, i18n.language, userValues.subscription])

  const handleAddSubscription = packageId => {
    handleClickOpenSubscriptionDialog()
    setPackageId(packageId)
  }

  const handleSubscriptionNow = packageId => {
    if (validateForm.current.checkValidity()) {
      setIsSubmitted(true)
      if (payType === "credit_card" && validateForm.current) {
        validateForm.current.submit()
        setIsSubmitted(false)
      } else {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/create-and-pay-subscribe-order`,
            {
              payType: payType,
              packageID: packageId,
              contactInfo: {
                name: checkoutForm.userNameModal,
                phone: checkoutForm.phoneNumber,
                email: checkoutForm.emailAddress,
              },
              cardName: checkoutForm.cardName,
              cardNumber: checkoutForm.cardNumber,
              cardCVV: checkoutForm.cardCVV,
              cardEndMonth: checkoutForm.cardEndMonth,
              cardEndYear: checkoutForm.cardEndYear,
            },
            { headers: { Authorization: `Bearer ${jwtForProfile}` } }
          )
          .then(() => {
            setOpenAlertSuccessSubscription(true)
            setTimeout(() => {
              setOpenAlertSuccessSubscription(false)
            }, 3000)
            setTimeout(() => {
              navigate("/")
            }, 3500)
          })
      }
    } else {
      if (!checkoutForm.userNameModal) {
        return setErrorMassege({
          userNameModal: true,
          userNameModalText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.phoneNumber) {
        return setErrorMassege({
          phoneNumber: true,
          phoneNumberText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.emailAddress) {
        return setErrorMassege({
          emailAddress: true,
          emailAddressText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardName) {
        return setErrorMassege({
          cardName: true,
          cardNameText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardNumber) {
        return setErrorMassege({
          cardNumber: true,
          cardNumberText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardCVV) {
        return setErrorMassege({
          cardCVV: true,
          cardCVVText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndMonth) {
        return setErrorMassege({
          cardEndMonth: true,
          cardEndMonthText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndYear) {
        return setErrorMassege({
          cardEndYear: true,
          cardEndYearText: t("errors.requiredField"),
        })
      }
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
    }
  }

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/get-fav-books`, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          setFavBooks(res.data)
        })
    }
  }, [jwtForProfile, i18n.language])

  const isMobile = useMediaQuery("(max-width:959.95px)")
  const isMiniMobile = useMediaQuery("(max-width:500px)")

  const firstName = userValues.firstName && userValues.firstName
  const lastName = userValues.lastName && userValues.lastName

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-orders`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
        },
      })
      .then(res => {
        setOrders(res.data)
      })
  }, [jwtForProfile, i18n.language])

  const trackThisOrder = (orderId, orderSt) => {
    setOrderIdForDetails(orderId)
    setOrderStatusState(orderSt)
  }
  const goBackTraking = () => {
    setOrderIdForDetails()
    setOrderStatusState()
  }
  const handleDeleteSubscription = () => {
    axios
      .delete(`${process.env.GATSBY_API_URL}/subscriptions/${subscription.id}`, {
        headers: { Authorization: `Bearer ${jwtForProfile}` },
      })
      .then(() => {
        if (windowGlobal) {
          windowGlobal.location.reload()
        }
      })
  }

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
    // axios
  }, [])

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    }
  }

  const formatDate = theDate => {
    let date = new Date(theDate)
    let fullDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    return fullDate
  }

  if (documentGlobal) {
    if (documentGlobal.getElementById("cardCVV")) {
      documentGlobal.getElementById("cardCVV").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardNumber")) {
      documentGlobal.getElementById("cardNumber").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardEndMonth")) {
      documentGlobal.getElementById("cardEndMonth").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardEndYear")) {
      documentGlobal.getElementById("cardEndYear").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("phoneNumber")) {
      documentGlobal.getElementById("phoneNumber").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
  }

  return (
    <>
      <SEO title={t("myProfile.myProfile")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle
        title={firstName + " " + lastName}
        subTitle={t("myProfile.myProfile")}
        image={userValues.profileImage ? `${process.env.GATSBY_API_URL}${userValues.profileImage.url}` : UserImage}
        border={true}
      />
      <Box component="div" display={jwtForProfile ? `block` : `none`}>
        <Grid container className={classes.profileGridContainer}>
          {!isMobile && (
            <Grid item sm={12} xs={12} md={3} className={classes.sideBarGrid} component="div">
              <Container className={classes.sideBarContainer}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleChangeTabs}
                  aria-label="Vertical tabs example"
                  className={classes.myProfileTabs}
                >
                  <Tab label={t("myProfile.myInformation")} />
                  <Tab label={t("myProfile.socialist")} />
                  <Tab label={t("myProfile.Favorite")} />
                  <Tab label={t("myProfile.trackMyShipment")} />
                </Tabs>

                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={false}
                  className={`${classes.myProfileTabs} ${classes.myProfileSecondTabs}`}
                >
                  {roleForProfile === "agent" && <Tab label={t("myProfile.adminPanel")} onClick={goToAdmin} />}
                  <Tab label={t("myProfile.signoutBtn")} onClick={signout} />
                </Tabs>
              </Container>
            </Grid>
          )}
          <Grid item container sm={12} md={9} className={classes.contentGrid} component="div">
            <Grid item xs={12} sm={12} md={8} className={classes.tableGrid}>
              {!isMobile && (
                <>
                  <TabPanel value={tabValue} index={0}>
                    {/* desktop Form Going Hereeeeeeeeeeeeee */}
                    <UserEditInfos />
                  </TabPanel>
                  <TabPanel value={subscriptionValue} index={1}>
                    <Grid container justify="space-evenly" display="flex" alignItems="flex-start">
                      <Grid item xs={12}>
                        {Object.keys(subscription).length > 0 && (
                          <Paper className={`${classes.subscriptionCurrentPackagesPaper} `}>
                            {subscription.subscription_package && (
                              <>
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  className={classes.currentPackageTextGreen}
                                >
                                  {t("myProfile.currentSubsc")}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  className={classes.currentPackageTextGreen}
                                >
                                  {subscription.subscription_package.duration + " " + t("myProfile.durations")} /{" "}
                                  {subscription.subscription_package.price.toFixed(2) + " " + t("myProfile.price")}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  className={classes.currentPackageTextBlue}
                                >
                                  {t("myProfile.reNewSebsic") + " " + subscription.startDate}
                                </Typography>
                              </>
                            )}
                            <Button
                              variant="contained"
                              color="secondary"
                              className={classes.deleteOrderIdBtn}
                              onClick={() => handleDeleteSubscription()}
                            >
                              {t("myProfile.delete")}
                            </Button>
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="space-evenly"
                      display="flex"
                      alignItems="flex-start"
                      className={`${classes.subscriptionPackagesGrid}`}
                    >
                      {Object.keys(subscription).length > 0 &&
                        subscriptionPackages.length > 0 &&
                        subscriptionPackages
                          .filter(item => item.id !== subscription.subscription_package.id)
                          .map(item => (
                            <Grid
                              item
                              xs={subscriptionPackages.length - 1 >= 4 ? 3 : 4}
                              key={item.id}
                              className={classes.subscriptionPackagesSingle}
                            >
                              <Paper
                                className={classes.subscriptionPackagesPaper}
                                style={{
                                  borderColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                }}
                              >
                                <Typography /* className={classes.packageDetails} */>
                                  {item.duration}
                                  {" " + t("myProfile.durations")}
                                </Typography>
                                <Typography className={classes.packageDetailsPrice}>
                                  {item.discountPrice
                                    ? (item.discountPrice * currencyRateState).toFixed(2)
                                    : (item.price * currencyRateState).toFixed(2)}
                                  {currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>} {t("myProfile.price")}
                                </Typography>
                                <Button
                                  onClick={() => handleAddSubscription(item.id)}
                                  style={{
                                    backgroundColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                  }}
                                >
                                  {t("myProfile.subscribeNow")}
                                </Button>
                                <Typography className={classes.packageDetails}>{item.title}</Typography>
                              </Paper>
                            </Grid>
                          ))}
                      {Object.keys(subscription).length === 0 &&
                        subscriptionPackages.length > 0 &&
                        subscriptionPackages.map(item => (
                          <Grid
                            item
                            xs={subscriptionPackages.length >= 4 ? 3 : 4}
                            key={item.id}
                            className={classes.subscriptionPackagesSingle}
                          >
                            <Paper
                              className={classes.subscriptionPackagesPaper}
                              style={{
                                borderColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                              }}
                            >
                              <Typography className={classes.packageDetails}>
                                {/* ********************==*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=* */}
                                {item.duration + " " + t("myProfile.durations")}
                              </Typography>
                              <Typography className={classes.packageDetailsPrice}>
                                {item.discountPrice
                                  ? currencyRateState > 0
                                    ? (item.discountPrice * currencyRateState).toFixed(2)
                                    : item.discountPrice.toFixed(2)
                                  : currencyRateState > 0
                                  ? (item.price * currencyRateState).toFixed(2)
                                  : item.price.toFixed(2)}
                                {currencyRateState > 1 ? (
                                  <span style={{ marginRight: 5 }}>&#8378;</span>
                                ) : (
                                  <span style={{ marginRight: 5 }}>&#36;</span>
                                )}
                                {t("myProfile.price")}
                              </Typography>
                              <Button
                                onClick={() => handleAddSubscription(item.id)}
                                style={{
                                  backgroundColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                }}
                              >
                                {t("myProfile.subscribeNow")}
                              </Button>
                              <Typography className={classes.packageDetails}>{item.title}</Typography>
                            </Paper>
                          </Grid>
                        ))}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {Object.keys(favBooks).length !== 0 && <FavBookList books={favBooks} />}
                  </TabPanel>
                  <TabPanel value={tabValue} index={3} className={classes.trackingSliderTab}>
                    {!orderIdForDetails ? (
                      orders
                        .filter(item => item.shippingCost !== null)
                        .map(item => (
                          <Box key={item.id}>
                            <Button
                              variant="text"
                              className={classes.orderIdBtn}
                              onClick={() => trackThisOrder(item.id, item.status)}
                            >
                              {t("myProfile.order")} #{item.id} {" - "}
                              {formatDate(item.created_at)}
                            </Button>
                            <br />
                          </Box>
                        ))
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          primary=""
                          startIcon={i18n.language === "ar" ? <ArrowRightAltIcon /> : <KeyboardBackspaceIcon />}
                          onClick={goBackTraking}
                        >
                          {t("myProfile.goBack")}
                        </Button>
                        <SingleOrder orderId={orderIdForDetails} orderStatus={orderStatusState} />
                      </>
                    )}
                  </TabPanel>
                </>
              )}
              {isMobile && (
                <>
                  <Container>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{t("myProfile.myInformation")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* Phone Form Going Hereeeeeeeeeeeeeee */}
                        <UserEditInfos />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>{t("myProfile.socialist")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row" justify="space-evenly" display="flex" alignItems="flex-start">
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              {Object.keys(subscription).length > 0 && (
                                <Paper className={`${classes.subscriptionCurrentPackagesPaper} `}>
                                  {subscription.subscription_package && (
                                    <>
                                      <Typography
                                        variant="subtitle1"
                                        component="p"
                                        className={classes.currentPackageTextGreen}
                                      >
                                        {t("myProfile.currentSubsc")}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        component="p"
                                        className={classes.currentPackageTextGreen}
                                      >
                                        {subscription.subscription_package.duration + " " + t("myProfile.durations")} /{" "}
                                        {subscription.subscription_package.price.toFixed(2) +
                                          " " +
                                          t("myProfile.price")}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        component="p"
                                        className={classes.currentPackageTextBlue}
                                      >
                                        {t("myProfile.reNewSebsic") + " " + subscription.startDate}
                                      </Typography>
                                    </>
                                  )}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.deleteOrderIdBtn}
                                    onClick={() => handleDeleteSubscription()}
                                  >
                                    {t("myProfile.delete")}
                                  </Button>
                                </Paper>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} container className={`${classes.subscriptionPackagesGrid}`}>
                            {Object.keys(subscription).length > 0 &&
                              subscriptionPackages.length > 0 &&
                              subscriptionPackages
                                .filter(item => item.id !== subscription.subscription_package.id)
                                .map(item => (
                                  <Grid
                                    item
                                    xs={!isMiniMobile ? (subscriptionPackages.length - 1 >= 4 ? 6 : 4) : 12}
                                    key={item.id}
                                    className={classes.subscriptionPackagesSingle}
                                  >
                                    <Paper
                                      className={classes.subscriptionPackagesPaper}
                                      style={{
                                        borderColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                      }}
                                    >
                                      <Typography className={classes.packageDetails}>
                                        {item.duration + " " + t("myProfile.durations")}
                                      </Typography>
                                      <Typography className={classes.packageDetailsPrice}>
                                        {item.discountPrice
                                          ? item.discountPrice.toFixed(2) + " " + t("myProfile.price")
                                          : item.price.toFixed(2) + " " + t("myProfile.price")}
                                      </Typography>
                                      <Button
                                        onClick={() => handleAddSubscription(item.id)}
                                        style={{
                                          backgroundColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                        }}
                                      >
                                        {t("myProfile.subscribeNow")}
                                      </Button>
                                      <Typography className={classes.packageDetails}>{item.title}</Typography>
                                    </Paper>
                                  </Grid>
                                ))}
                            {Object.keys(subscription).length === 0 &&
                              subscriptionPackages.length > 0 &&
                              subscriptionPackages.map(item => (
                                <Grid
                                  item
                                  xs={subscriptionPackages.length >= 4 ? 6 : 4}
                                  key={item.id}
                                  className={classes.subscriptionPackagesSingle}
                                >
                                  <Paper
                                    className={classes.subscriptionPackagesPaper}
                                    style={{
                                      borderColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                    }}
                                  >
                                    <Typography className={classes.packageDetails}>
                                      {item.duration + " " + t("myProfile.durations")}
                                    </Typography>
                                    <Typography className={classes.packageDetailsPrice}>
                                      {item.discountPrice
                                        ? item.discountPrice.toFixed(2) + " " + t("myProfile.price")
                                        : item.price.toFixed(2) + " " + t("myProfile.price")}
                                    </Typography>
                                    <Button
                                      onClick={() => handleAddSubscription(item.id)}
                                      style={{
                                        backgroundColor: item.color.startsWith("#") ? item.color : `#${item.color}`,
                                      }}
                                    >
                                      {t("myProfile.subscribeNow")}
                                    </Button>
                                    <Typography className={classes.packageDetails}>{item.title}</Typography>
                                  </Paper>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>{t("myProfile.Favorite")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.keys(favBooks).length !== 0 && <FavBookList books={favBooks} />}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>{t("myProfile.trackMyShipment")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.odrerIdAccordinDetailsMobile}>
                        {!orderIdForDetails ? (
                          orders.map(item => (
                            <Button
                              key={item.id}
                              variant="text"
                              className={`${classes.orderIdBtn} ${classes.orderIdBtnMobile}`}
                              onClick={() => trackThisOrder(item.id, item.status)}
                            >
                              {t("myProfile.order")} #{item.id}
                            </Button>
                          ))
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              primary=""
                              startIcon={i18n.language === "ar" ? <ArrowRightAltIcon /> : <KeyboardBackspaceIcon />}
                              onClick={goBackTraking}
                            >
                              {t("myProfile.goBack")}
                            </Button>
                            <SingleOrder orderId={orderIdForDetails} orderStatus={orderStatusState} />
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExitToAppIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        onClick={signout}
                      >
                        <Typography className={classes.heading}>{t("myProfile.signoutBtn")}</Typography>
                      </AccordionSummary>
                    </Accordion>
                  </Container>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SnackbarProvider maxSnack={3}>
          <Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {t("myProfile.dataChangedsuccess")}
            </Alert>
          </Snackbar>
          <Snackbar open={openAlertSuccessSubscription} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {t("myProfile.successSubscriptionOrder")}
            </Alert>
          </Snackbar>
          {/* <Snackbar
            open={openAlertError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {errorMassegeCatch}
            </Alert>
          </Snackbar> */}
          <Snackbar open={openAlertErrorOnSubmit} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {t("errors.fieldsWrong")}
            </Alert>
          </Snackbar>
        </SnackbarProvider>
        <Dialog
          open={openSubscriptionDialog}
          onClose={handleCloseSubscriptionDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <form
              ref={validateForm}
              id="validateForm"
              method="post"
              action="https://gwthani.com/sendPaymentSubscription.php"
            >
              <input type="hidden" value={jwtForProfile} name="userToken" />
              <input type="hidden" value={packageId} name="packageId" />
              <input type="hidden" value={payType} name="paymentType" />
              <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                <TextField
                  id="userNameModal"
                  type="text"
                  label={t("checkout.userNameModal")}
                  variant="outlined"
                  name="userNameModal"
                  onChange={handleChangeModalForm("userNameModal")}
                  error={errorMassege.userNameModal}
                  helperText={errorMassege.userNameModalText}
                />
              </FormControl>
              <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                <TextField
                  id="phoneNumber"
                  type="text"
                  label={t("checkout.phoneNumber")}
                  variant="outlined"
                  name="phoneNumber"
                  onChange={handleChangeModalForm("phoneNumber")}
                  error={errorMassege.phoneNumber}
                  helperText={errorMassege.phoneNumberText}
                />
              </FormControl>
              <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  label={t("checkout.emailAddress")}
                  variant="outlined"
                  error={errorMassege.emailAddress}
                  helperText={errorMassege.emailAddressText}
                  onChange={handleChangeModalForm("emailAddress")}
                />
              </FormControl>

              <AppBar position="static" color="default" className={classes.tabsAppBar}>
                <Tabs
                  value={paymentTabValues}
                  onChange={handleChangePaymentTabs}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    label={t("myProfile.creditCard")}
                    className={classes.paymentTypesTabs}
                    icon={<CreditCardIcon className={classes.paymentTypeTabsIcon} />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Western Union"
                    className={classes.paymentTypesTabs}
                    icon={<img src={WesternUnion} className={classes.paymentTypeTabsIcon} alt="tabs icon" />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={t("myProfile.transfer")}
                    className={classes.paymentTypesTabs}
                    icon={<img src={Transfer} className={classes.paymentTypeTabsIcon} alt="tabs icon" />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="WhatsApp"
                    className={classes.paymentTypesTabs}
                    icon={<WhatsAppIcon className={classes.paymentTypeTabsIcon} />}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={paymentTabValues} index={0} className={classes.tabpanelStyle}>
                <Typography variant="subtitle1" component="h3">
                  {t("checkout.paymentDetails")}
                </Typography>
                <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                  <TextField
                    required
                    id="cardName"
                    name="cardName"
                    type="text"
                    label={t("checkout.cardName")}
                    variant="outlined"
                    onChange={handleChangeModalForm("cardName")}
                    error={errorMassege.cardName}
                    helperText={errorMassege.cardNameText}
                  />
                </FormControl>
                <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                  <TextField
                    required
                    id="cardNumber"
                    name="cardNumber"
                    type="text"
                    label={t("checkout.cardNumber")}
                    variant="outlined"
                    onChange={handleChangeModalForm("cardNumber")}
                    error={errorMassege.cardNumber}
                    helperText={errorMassege.cardNumberText}
                  />
                </FormControl>
                <Grid container>
                  <Grid item xs={4} className={classes.paddingLeftRight}>
                    <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                      <TextField
                        required
                        id="cardCVV"
                        name="cardCVV"
                        type="password"
                        label={t("checkout.cardCVV")}
                        variant="outlined"
                        onChange={handleChangeModalForm("cardCVV")}
                        error={errorMassege.cardCVV}
                        helperText={errorMassege.cardCVVText}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} className={classes.paddingLeftRight}>
                    <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                      <TextField
                        required
                        id="cardEndMonth"
                        name="cardEndMonth"
                        label={t("checkout.cardEndMonth")}
                        variant="outlined"
                        error={errorMassege.cardEndMonth}
                        helperText={errorMassege.cardEndMonthText}
                        onChange={handleChangeModalForm("cardEndMonth")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} className={classes.paddingLeftRight}>
                    <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                      <TextField
                        required
                        id="cardEndYear"
                        name="cardEndYear"
                        label={t("checkout.cardEndYear")}
                        variant="outlined"
                        error={errorMassege.cardEndYear}
                        helperText={errorMassege.cardEndYearText}
                        onChange={handleChangeModalForm("cardEndYear")}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={paymentTabValues} index={1} className={classes.tabpanelStyle}>
                <div dangerouslySetInnerHTML={{ __html: paymentOptions.pay_western_union }} />
              </TabPanel>
              <TabPanel value={paymentTabValues} index={2} className={classes.tabpanelStyle}>
                <div dangerouslySetInnerHTML={{ __html: paymentOptions.pay_eft }} />
              </TabPanel>
              <TabPanel value={paymentTabValues} index={3} className={classes.tabpanelStyle}>
                <div dangerouslySetInnerHTML={{ __html: paymentOptions.pay_whatsapp }} />
              </TabPanel>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscriptionDialog} color="primary">
              {t("myProfile.cancel")}
            </Button>
            <Button onClick={() => handleSubscriptionNow(packageId)}>{t("myProfile.subscribeNow")}</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">{t("myProfile.noPremissions")}</Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}
export default MyProfile
