import axios from "axios"
import MuiAlert from "@material-ui/lab/Alert"
import { useTranslation } from "react-i18next"
import { useSnackbar, SnackbarProvider } from "notistack"
import React, { useState, useRef, useEffect } from "react"

import {
  Grid,
  Select,
  Dialog,
  Button,
  Checkbox,
  Snackbar,
  FormGroup,
  TextField,
  makeStyles,
  InputLabel,
  Typography,
  ButtonGroup,
  FormControl,
  useMediaQuery,
  DialogContent,
  DialogActions,
  FormControlLabel,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import Unlock from "../../static/images/Icons/unlock-alt-solid.svg"

const UserEditInfos = () => {
  const classes = useStyles()
  const imageRef = useRef(null)
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const phoneRegexd = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{4})$/
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/

  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [imageChanged, setImageChanged] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [checkedPassword, setCheckedPassword] = useState(false)
  const [errorMassegeCatch, setErrorMassegeCatch] = useState("")
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [checkBeforeSubmit, setCheckBeforeSubmit] = useState(false)
  const [openVerifyCodeModal, setOpenVerifyCodeModal] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [checkboxState, setCheckboxState] = useState({
    checkedB: false,
  })
  const [verifiAccount, setVerifiAccount] = useState({
    code: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    username: false,
    usernameText: "",
    passwordOld: false,
    passwordOldText: "",
    phoneNumber: false,
    phoneNumberText: "",
    birthDate: false,
    birthDateText: "",
    addressCountry: false,
    addressCountryText: "",
    addressCity: false,
    addressCityText: "",
    addressText: false,
    addressErrorText: "",
    addressZip: false,
    addressZipText: "",
    emailAddress: "",
    emailAddressText: "",
    code: "",
    codeText: "",
  })
  const [userEnterdValues, setUserEnterdValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    profileImage: "",
    passwordOld: "",
    passwordNew: "",
    passwordNewConfirm: "",
    phoneNumber: "",
    birthDate: "",
    addressCountry: "",
    addressCity: "",
    addressText: "",
    addressZip: "",
    emailAddress: "",
  })
  const [userValues, setUserValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    profileImage: "",
    passwordOld: "",
    passwordNew: "",
    phoneNumber: "",
    birthDate: "",
    addressCountry: "",
    addressCity: "",
    addressText: "",
    addressZip: "",
    emailAddress: "",
  })

  const isMobile = useMediaQuery("(max-width:959.95px)")
  const booleanReacuird = checkboxState.checkedB ? true : false
  let userRol
  let localCurrency
  let jwtForProfile
  let idForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
    idForProfile = windowGlobal.sessionStorage.getItem("userId")
    userRol = windowGlobal.sessionStorage.getItem("userRl")
    localCurrency = windowGlobal.localStorage.getItem("currencyKey")
  }

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    setOpenAlertError(false)
    setOpenAlertErrorOnSubmit(false)
  }

  const handleChange = prop => event => {
    if (prop === "userName" && isSubmitted) {
      enterdUserName()
    } else if (prop === "emailAddress" && isSubmitted) {
      enterdEmailAddress()
    } else if (prop === "phoneNumber" && isSubmitted) {
      enterdPhoneNumber()
    }

    setUserEnterdValues({ ...userEnterdValues, [prop]: event.target.value })
    setCheckBeforeSubmit(true)
  }

  const handleChangeVerify = prop => event => {
    setVerifiAccount({ ...verifiAccount, [prop]: event.target.value })
  }

  const enterdPasswordMatch = () => {
    if (checkedPassword) {
      if (userEnterdValues.passwordNew === userEnterdValues.passwordNewConfirm) {
        setErrorMassege({ ...errorMassege, password: false, passwordText: "" })
        return true
      } else {
        setErrorMassege({
          ...errorMassege,
          password: true,
          passwordText: t("errors.passwordNotMatch"),
        })
        return false
      }
    }
  }

  const handleChangeCheckbox = event => {
    setCheckboxState({ checkedB: event.target.checked })
  }

  const enterdUserName = () => {
    if (emailRegExp.test(userValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      return true
    } else if (phoneRegexd.test(userValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        username: true,
        usernameText: t("errors.invalidUserName"),
      })
      return false
    }
  }

  const enterdPhoneNumber = () => {
    if (phoneRegexd.test(userEnterdValues.phoneNumber)) {
      setErrorMassege({
        ...errorMassege,
        phoneNumber: false,
        phoneNumberText: "",
      })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        phoneNumber: true,
        phoneNumberText: t("errors.invalidPhoneNumber"),
      })
      return false
    }
  }
  const enterdEmailAddress = () => {
    if (emailRegExp.test(userEnterdValues.emailAddress)) {
      setErrorMassege({
        ...errorMassege,
        emailAddress: false,
        emailAddressText: "",
      })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        emailAddress: true,
        emailAddressText: t("errors.invalidEmailAddress"),
      })
      return false
    }
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/checkout-countries`, {
        headers: { "Accept-language": i18n.language },
      })
      .then(res => {
        setCountries(res.data)
      })
  }, [])

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${jwtForProfile}` },
        })
        .then(res => {
          const data = res.data
          setUserValues({
            ...userValues,
            phoneNumber: data.phone ? data.phone : "",
            userName: data.username ? data.username : "",
            lastName: data.lastName ? data.lastName : "",
            profileImage: data.avatar ? data.avatar : "",
            emailAddress: data.email ? data.email : "",
            birthDate: data.birthDate ? data.birthDate : "",
            firstName: data.firstName ? data.firstName : "",
            addressZip: data.addresses.length > 0 && data.addresses[0].zip ? data.addresses[0].zip : "",
            addressCity: data.addresses.length > 0 && data.addresses[0].city ? data.addresses[0].city : "",
            addressCountry: data.addresses.length > 0 && data.addresses[0].country ? data.addresses[0].country : "",
            addressText:
              data.addresses.length > 0 && data.addresses[0].addressText ? data.addresses[0].addressText : "",
          })
          setUserEnterdValues({
            ...userEnterdValues,
            userName: data.username ? data.username : "",
            lastName: data.lastName ? data.lastName : "",
            firstName: data.firstName ? data.firstName : "",
            profileImage: data.avatar ? data.avatar : "",
            birthDate: data.birthDate ? data.birthDate : "",
            phoneNumber: data.phone ? data.phone : "",
            emailAddress: data.email ? data.email : "",
            addressCountry: data.addresses.length > 0 && data.addresses[0].country ? data.addresses[0].country : "",
            addressCity: data.addresses.length > 0 && data.addresses[0].city ? data.addresses[0].city : "",
            addressText:
              data.addresses.length > 0 && data.addresses[0].addressText ? data.addresses[0].addressText : "",
            addressZip: data.addresses.length > 0 && data.addresses[0].zip ? data.addresses[0].zip : "",
          })
        })
    }
  }, [jwtForProfile])

  const handleChangeCheck = event => {
    setCheckedPassword(event.target.checked)
  }

  let formData
  if (windowGlobal) {
    formData = new FormData()
    formData.append("refId", idForProfile)
    formData.append("ref", "user")
    formData.append("field", "avatar")
    formData.append("source", "users-permissions")
    formData.append("files", imageRef.current && imageRef.current.files[0])
  }

  const validateValues = () => {
    if (userEnterdValues.firstName !== userValues.firstName || userEnterdValues.lastName !== userValues.lastName) {
      return true
    } else if (userEnterdValues.phoneNumber !== userValues.phoneNumber) {
      return true
    } else if (userEnterdValues.birthDate !== userValues.birthDate) {
      return true
    } else if (userEnterdValues.addressCountry !== userValues.addressCountry) {
      return true
    } else if (userEnterdValues.addressCity !== userValues.addressCity) {
      return true
    } else if (userEnterdValues.addressText !== userValues.addressText) {
      return true
    } else if (userEnterdValues.addressZip !== userValues.addressZip) {
      return true
    } else if (userEnterdValues.emailAddress !== userValues.emailAddress) {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)

    if (checkBeforeSubmit && userRol !== "agent" && validateValues()) {
      if (userEnterdValues.phoneNumber !== userValues.phoneNumber) {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/phone-confirm`,
            {
              phone: userEnterdValues.phoneNumber,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
                "Accept-Language": i18n.language,
              },
            }
          )
          .then(() => {
            setLoading(false)
            setDisabledBtn(false)
            setOpenVerifyCodeModal(true)
          })
          .catch(err => {
            enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
          })
      } else if (userEnterdValues.emailAddress !== userValues.emailAddress) {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/email-confirm`,
            {
              email: userEnterdValues.emailAddress,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
                "Accept-Language": i18n.language,
              },
            }
          )
          .then(() => {
            setLoading(false)
            setDisabledBtn(false)
            setOpenVerifyCodeModal(true)
          })
          .catch(err => {
            enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
          })
      } else {
        axios
          .put(
            `${process.env.GATSBY_API_URL}/users/${idForProfile}`,
            {
              firstName: userEnterdValues.firstName,
              lastName: userEnterdValues.lastName,
              birthDate: userEnterdValues.birthDate,
              addresses: [
                {
                  country: userEnterdValues.addressCountry,
                  city: userEnterdValues.addressCity,
                  addressText: userEnterdValues.addressText,
                  zip: userEnterdValues.addressZip,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
                "Accept-Language": i18n.language,
                currency: localCurrency,
              },
            }
          )
          .then(() => {
            setLoading(false)
            setDisabledBtn(false)
            setCheckBeforeSubmit(false)
            enqueueSnackbar(t("myProfile.dataChangedsuccess"), { variant: "success", autoHideDuration: 3000 })
            setTimeout(() => {
              windowGlobal.location.reload(true)
            }, 3500)
          })
          .catch(err => {
            setLoading(false)
            setDisabledBtn(false)
            enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
          })
      }
    } else if (imageChanged) {
      axios
        .post(`${process.env.GATSBY_API_URL}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setDisabledBtn(false)
          setLoading(false)
          setCheckBeforeSubmit(false)
          enqueueSnackbar(t("myProfile.dataChangedsuccess"), { variant: "success", autoHideDuration: 3000 })
          setTimeout(() => {
            windowGlobal.location.reload(true)
          }, 3500)
        })
    } else if (checkedPassword) {
      if (enterdPasswordMatch()) {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/reset-password`,
            {
              oldPassword: userEnterdValues.passwordOld,
              newPassword: userEnterdValues.passwordNew,
              confirmNewPassword: userEnterdValues.passwordNewConfirm,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtForProfile}`,
                "Accept-Language": i18n.language,
              },
            }
          )
          .then(() => {
            setLoading(false)
            setDisabledBtn(false)
            enqueueSnackbar(t("myProfile.dataChangedsuccess"), { variant: "success", autoHideDuration: 3000 })
          })
          .catch(err => {
            setLoading(false)
            setDisabledBtn(false)
            enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
          })
      } else {
        setLoading(false)
        enqueueSnackbar(t("errors.fieldsWrong"), { variant: "error", autoHideDuration: 3000 })
      }
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
      enqueueSnackbar(t("myProfile.noChangesOnInputs"), { variant: "error", autoHideDuration: 3000 })
    }
  }

  const fileTypes = ["jpg", "jpeg", "png"]
  const openFile = function (event) {
    let input = event.target
    let reader = new FileReader()
    if (input.files && input.files[0]) {
      var extension = input.files[0].name.split(".").pop().toLowerCase(),
        isSuccess = fileTypes.indexOf(extension) > -1

      if (isSuccess) {
        reader.onload = function (e) {
          setImageChanged(true)
          let dataURL = reader.result
          let output = documentGlobal.getElementById("output")
          output.src = dataURL
        }

        reader.readAsDataURL(input.files[0])
      } else {
        alert("image type not allowed. Image type must be jpg, jpeg or png")
        let output = documentGlobal.getElementById("output")
        output.src = ""
      }
    }
  }

  const handleCloseGetCodeModal = () => {
    setOpenVerifyCodeModal(false)
  }

  const handleGetCodeSubmit = e => {
    e.preventDefault()
    setLoadingConfirm(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    axios
      .post(
        `${process.env.GATSBY_API_URL}/phone-confirm-code`,
        {
          code: verifiAccount.code,
        },
        {
          headers: {
            Authorization: `Bearer ${windowGlobal.sessionStorage.getItem("jwtCode")}`,
            "Accept-Language": i18n.language,
          },
        }
      )
      .then(() => {
        if (userEnterdValues.phoneNumber !== userValues.phoneNumber) {
          axios
            .put(
              `${process.env.GATSBY_API_URL}/users/${idForProfile}`,
              {
                phone: userEnterdValues.phoneNumber,
              },
              {
                headers: {
                  Authorization: `Bearer ${jwtForProfile}`,
                  "Accept-Language": i18n.language,
                  currency: localCurrency,
                },
              }
            )
            .then(() => {
              setTimeout(() => {
                windowGlobal.location.reload(true)
              }, 3500)
              setTimeout(() => setOpenVerifyCodeModal(false), 1500)
              enqueueSnackbar(t("myProfile.dataChangedsuccess"), { variant: "success", autoHideDuration: 3000 })
            })
            .catch(err => {
              enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
            })

          setLoading(false)
          setDisabledBtn(false)
          setLoadingConfirm(false)
          setCheckBeforeSubmit(false)
        } else if (userEnterdValues.emailAddress !== userValues.emailAddress) {
          axios
            .put(
              `${process.env.GATSBY_API_URL}/users/${idForProfile}`,
              {
                email: userEnterdValues.emailAddress,
                username: userEnterdValues.emailAddress,
              },
              {
                headers: {
                  Authorization: `Bearer ${jwtForProfile}`,
                  "Accept-Language": i18n.language,
                  currency: localCurrency,
                },
              }
            )
            .then(() => {
              setTimeout(() => {
                windowGlobal.location.reload(true)
              }, 3500)
              setTimeout(() => setOpenVerifyCodeModal(false), 1500)
              enqueueSnackbar(t("myProfile.dataChangedsuccess"), { variant: "success", autoHideDuration: 3000 })
            })
            .catch(err => {
              enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
            })

          setLoading(false)
          setDisabledBtn(false)
          setLoadingConfirm(false)
          setCheckBeforeSubmit(false)
        } else {
          setLoadingConfirm(false)
          setDisabledBtn(false)
        }
      })
      .catch(err => {
        setLoadingConfirm(false)
        setDisabledBtn(false)
        enqueueSnackbar(err.data.message, { variant: "error", autoHideDuration: 3000 })
      })
  }

  const resendCode = () => {
    if (userEnterdValues.phoneNumber !== userValues.phoneNumber) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/phone-confirm`,
          {
            phone: userEnterdValues.phoneNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtForProfile}`,
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(() => {
          setLoading(false)
          setDisabledBtn(false)
          setOpenVerifyCodeModal(true)
        })
        .catch(err => {
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    } else if (userEnterdValues.emailAddress !== userValues.emailAddress) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/email-confirm`,
          {
            email: userEnterdValues.emailAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtForProfile}`,
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(() => {
          setLoading(false)
          setDisabledBtn(false)
          setOpenVerifyCodeModal(true)
        })
        .catch(err => {
          enqueueSnackbar(err.response.data.message, { variant: "error", autoHideDuration: 3000 })
        })
    }
  }

  return (
    <>
      {!isMobile && (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <TextField
                  required={!userRol}
                  disabled={userRol === "agent" ? true : false}
                  className={userRol === "agent" ? classes.desabledFeilds : undefined}
                  fullWidth
                  id="firstName"
                  type="text"
                  label={t("signup.firstName")}
                  variant="outlined"
                  value={userEnterdValues.firstName}
                  onChange={handleChange("firstName")}
                  error={errorMassege.firstName}
                  helperText={errorMassege.firstNameText}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required={!userRol}
                  disabled={userRol === "agent" ? true : false}
                  className={userRol === "agent" ? classes.desabledFeilds : undefined}
                  fullWidth
                  id="lastName"
                  type="text"
                  label={t("signup.lastName")}
                  variant="outlined"
                  value={userEnterdValues.lastName}
                  onChange={handleChange("lastName")}
                  error={errorMassege.lastName}
                  helperText={errorMassege.lastNameText}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <TextField
              id="emailAddress"
              name="emailAddress"
              variant="outlined"
              type="text"
              label={t("checkout.emailAddress")}
              value={userEnterdValues.emailAddress}
              error={errorMassege.emailAddress}
              helperText={errorMassege.emailAddressText}
              onChange={handleChange("emailAddress")}
            />
          </FormControl>
          {/* <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
                <TextField
                  id="userName"
                  type="text"
                  disabled
                  label={t("signup.username")}
                  variant="outlined"
                  value={userValues.userName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  variant="outlined"
                  type="text"
                  placeholder={t("checkout.emailAddress")}
                  // onFocus={e => (e.target.type = "date")}
                  value={userEnterdValues.emailAddress}
                  error={errorMassege.emailAddress}
                  helperText={errorMassege.emailAddressText}
                  onChange={handleChange("emailAddress")}
                />
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("myProfile.phoneNumber")}
                  variant="outlined"
                  type="text"
                  value={userEnterdValues.phoneNumber}
                  error={errorMassege.phoneNumber}
                  helperText={errorMassege.phoneNumberText}
                  onChange={handleChange("phoneNumber")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="birthDate"
                  name="birthDate"
                  variant="outlined"
                  type="text"
                  placeholder={t("myProfile.birthDate")}
                  onFocus={e => (e.target.type = "date")}
                  value={userEnterdValues.birthDate}
                  error={errorMassege.birthDate}
                  helperText={errorMassege.birthDateText}
                  onChange={handleChange("birthDate")}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth variant="outlined">
                {/* <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label={t("myProfile.addressCountry")}
                  variant="outlined"
                  type="text"
                  value={userEnterdValues.addressCountry}
                  error={errorMassege.phoneNumber}
                  helperText={errorMassege.phoneNumberText}
                  onChange={handleChange("addressCountry")}
                /> */}
                <InputLabel htmlFor="addressCountry">{t("checkout.country")}</InputLabel>
                <Select
                  native
                  name="addressCountry"
                  label={t("myProfile.addressCountry")}
                  value={userEnterdValues.addressCountry}
                  onChange={handleChange("addressCountry")}
                  error={errorMassege.addressCountry}
                  helpertext={errorMassege.addressCountryText}
                >
                  {!userEnterdValues.addressCountry && <option aria-label="None" />}
                  {countries &&
                    countries.map(item => (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="addressCity"
                  name="addressCity"
                  variant="outlined"
                  type="text"
                  placeholder={t("myProfile.addressCity")}
                  value={userEnterdValues.addressCity}
                  error={errorMassege.addressCity}
                  helperText={errorMassege.addressCityText}
                  onChange={handleChange("addressCity")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="addressZip"
                  name="addressZip"
                  variant="outlined"
                  type="text"
                  placeholder={t("myProfile.addressZip")}
                  value={userEnterdValues.addressZip}
                  error={errorMassege.addressZip}
                  helperText={errorMassege.addressZipText}
                  onChange={handleChange("addressZip")}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  id="addressText"
                  name="addressText"
                  variant="outlined"
                  type="text"
                  placeholder={t("myProfile.addressText")}
                  value={userEnterdValues.addressText}
                  error={errorMassege.addressText}
                  helperText={errorMassege.addressErrorText}
                  onChange={handleChange("addressText")}
                  multiline
                  rows={4}

                  // id="addressText"
                  // multiline
                  // rows={4}
                  // label={t("myProfile.addressText")}
                  // variant="outlined"
                  // value={userEnterdValues.addressText}
                  // onChange={handleChange("addressText")}
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={checkedPassword} onChange={handleChangeCheck} name="checkedB" color="primary" />
              }
              label={t("myProfile.resetPassword")}
            />
          </FormGroup>
          {checkedPassword && (
            <>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={checkedPassword}
                  id="passwordOld"
                  name="password"
                  label={t("myProfile.passwordOld")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordOld}
                  error={errorMassege.passwordOld}
                  helperText={errorMassege.passwordOldText}
                  onChange={handleChange("passwordOld")}
                />
              </FormControl>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={checkedPassword}
                  id="password"
                  name="password"
                  label={t("myProfile.newPassword")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordNew}
                  error={errorMassege.passwordNew}
                  helperText={errorMassege.passwordText}
                  onChange={handleChange("passwordNew")}
                />
              </FormControl>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={booleanReacuird}
                  id="confPassword"
                  name="confPassword"
                  label={t("myProfile.newPasswordConfirm")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordNewConfirm}
                  error={errorMassege.password}
                  helperText={errorMassege.passwordText}
                  maxLength="5"
                  minLength="1"
                  onChange={handleChange("passwordNewConfirm")}
                />
              </FormControl>
            </>
          )}
          <Typography className={classes.profileImageLable} variant="body2" component="p">
            {t("myProfile.profileImage")}
          </Typography>
          <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
            <Grid container>
              <Grid item xs={12} sm={6} className={classes.profileImageButton}>
                <Button variant="contained" component="label">
                  {t("admin.uploadImage")}
                  <input
                    accept="image/*"
                    onChange={event => openFile(event)}
                    style={{ display: "none" }}
                    id="profileImage"
                    type="file"
                    name="avatar"
                    alt="image"
                    ref={imageRef}
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.profileImage}>
                {imageRef.current && imageRef.current.files[0] && <img id="output" alt="output" />}
              </Grid>
            </Grid>
          </FormControl>
          <ButtonGroup
            fullWidth={true}
            color="primary"
            aria-label="Create account"
            disabled={disabledBtn}
            loading={loading.loading}
            className={`${classes.button} ${classes.margin} ${classes.buttonSubmit} button`}
          >
            <Button type="submit" variant="contained" className={classes.createAccountBtn}>
              {loading && <CircularProgress size={26} />}
              {!loading && t("myProfile.submitBtn")}
            </Button>
          </ButtonGroup>
        </form>
      )}
      {/* Phone Tab */}

      {isMobile && (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <Grid container directoin="row" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  type="text"
                  label={t("signup.firstName")}
                  variant="outlined"
                  value={userValues.firstName}
                  onChange={handleChange("firstName")}
                  error={errorMassege.firstName}
                  helperText={errorMassege.firstNameText}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  type="text"
                  label={t("signup.lastName")}
                  variant="outlined"
                  value={userValues.lastName}
                  onChange={handleChange("lastName")}
                  error={errorMassege.lastName}
                  helperText={errorMassege.lastNameText}
                />
              </Grid>
            </Grid>
          </FormControl>
          {/* <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <TextField
              id="userName"
              type="text"
              disabled
              label={t("signup.username")}
              variant="outlined"
              value={userValues.userName}
            />
          </FormControl> */}
          <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <TextField
              id="emailAddress"
              name="emailAddress"
              variant="outlined"
              type="text"
              label={t("checkout.emailAddress")}
              value={userEnterdValues.emailAddress}
              error={errorMassege.emailAddress}
              helperText={errorMassege.emailAddressText}
              onChange={handleChange("emailAddress")}
            />
          </FormControl>
          <FormControl fullWidth className={`${classes.margin} ${classes.formConrol}`}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label={t("myProfile.phoneNumber")}
              variant="outlined"
              type="text"
              value={userEnterdValues.phoneNumber}
              error={errorMassege.phoneNumber}
              helperText={errorMassege.phoneNumberText}
              onChange={handleChange("phoneNumber")}
            />
          </FormControl>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={checkedPassword} onChange={handleChangeCheckbox} name="checkedB" color="primary" />
              }
              label={t("myProfile.resetPassword")}
            />
          </FormGroup>
          {checkedPassword && (
            <>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={booleanReacuird}
                  id="passwordOld"
                  name="password"
                  label={t("myProfile.passwordOld")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordOld}
                  error={errorMassege.passwordOld}
                  helperText={errorMassege.passwordOldText}
                  onChange={handleChange("passwordOld")}
                />
              </FormControl>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={booleanReacuird}
                  id="password"
                  name="password"
                  label={t("signup.password")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordNew}
                  error={errorMassege.passwordNew}
                  helperText={errorMassege.passwordText}
                  onChange={handleChange("passwordNew")}
                />
              </FormControl>
              <FormControl className={`${classes.margin} ${classes.formConrol}`} fullWidth>
                <TextField
                  required={booleanReacuird}
                  id="confPassword"
                  name="confPassword"
                  label={t("signup.passwordConfirm")}
                  variant="outlined"
                  type="password"
                  value={userEnterdValues.passwordNewConfirm}
                  error={errorMassege.password}
                  helperText={errorMassege.passwordText}
                  maxLength="5"
                  minLength="1"
                  onChange={handleChange("passwordNewConfirm")}
                />
              </FormControl>
            </>
          )}

          <ButtonGroup
            fullWidth={true}
            color="primary"
            aria-label="Create account"
            disabled={disabledBtn}
            loading={loading.loading}
            className={`${classes.button} ${classes.margin} ${classes.buttonSubmit} button`}
          >
            <Button type="submit" variant="contained" className={classes.createAccountBtn}>
              {loading && <CircularProgress size={26} className={classes.loading} />}
              {!loading && t("myProfile.submitBtn")}
            </Button>
          </ButtonGroup>
        </form>
      )}

      <Dialog open={openVerifyCodeModal} onClose={handleCloseGetCodeModal} aria-labelledby="form-dialog-title">
        <DialogActions>
          <Button
            onClick={handleCloseGetCodeModal}
            color="primary"
            className={`${classes.dialogActionsBtns} closeButton`}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent className={classes.dialogMainContent}>
          <img src={Unlock} alt="Unlock" className={classes.dialogSigninIcon} />
          <DialogContentText>
            {t("signup.verifyAccount")}
            <br />{" "}
            <Typography component="span" style={{ color: "#000" }}>
              {t("signup.signupVerificationCodeSentTo")}
            </Typography>
          </DialogContentText>
          <form onSubmit={handleGetCodeSubmit}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl fullWidth className={`${classes.marginForForms} ${classes.formsLabel}`}>
                  <TextField
                    // required
                    id="code"
                    type="text"
                    label={t("signup.code")}
                    variant="outlined"
                    inputProps={{
                      maxLength: "5",
                    }}
                    onChange={handleChangeVerify("code")}
                    error={errorMassege.code}
                    helperText={errorMassege.codeText}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="space-between" className={classes.marginForForms}>
              <Grid item xs={12}>
                <ButtonGroup width="100%" fullWidth={true} color="primary" aria-label="outlined primary button group">
                  <Button
                    disabled={disabledBtn}
                    // loading={loadingConfirm.loadingConfirm}
                    onClick={handleGetCodeSubmit}
                    variant="contained"
                  >
                    {loadingConfirm && <CircularProgress size={26} />}
                    {!loadingConfirm && t("signin.verifyCode")}
                  </Button>
                  <Button
                    disabled={disabledBtn}
                    onClick={resendCode}
                    // loading={loadingConfirm.loadingConfirm}
                  >
                    {loadingConfirm && <CircularProgress size={26} />}
                    {!loadingConfirm && t("signin.resendCode")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <SnackbarProvider maxSnack={3}>
        <Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {t("myProfile.dataChangedsuccess")}
          </Alert>
        </Snackbar>
        <Snackbar open={openAlertError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {errorMassegeCatch}
          </Alert>
        </Snackbar>
        <Snackbar open={openAlertErrorOnSubmit} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {t("errors.fieldsWrong")}
          </Alert>
        </Snackbar>
      </SnackbarProvider>
    </>
  )
}

export default UserEditInfos

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formConrol: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input, select, input:focus, select:focus, textarea, textarea:focus": {
      padding: "13px 12px",
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& input#userName:hover": {
      cursor: "not-allowed",
    },
    "& .MuiInputBase-formControl": {
      padding: 0,
    },
  },
  desabledFeilds: {
    "& input:hover": {
      cursor: "not-allowed",
    },
  },
  form: {
    "& .react-tel-input": {
      marginBottom: 15,
      direction: "ltr",
      flip: false,
      "& input": {
        width: "100%",
      },
      "& .react-tel-input .form-control": {
        width: "100% important",
      },
    },
  },
  buttonSubmit: {
    marginTop: 30,
    marginBottom: 30,
    "& .MuiCircularProgress-svg": {
      color: "#fff",
    },
  },
  profileImageLable: {
    marginTop: 30,
    marginBottom: 16,
  },
  profileDateLable: {
    marginBottom: 16,
    // marginTop: 16,
  },
  profileImage: {
    outlineWidth: 0,
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
    },
    "& img": {
      maxWidth: 175,
      height: "auto",
      textAlign: "center",
      borderRadius: 10,
    },
  },
  profileImageButton: {
    textAlign: "left",
    outlineWidth: 0,
  },
  dialogSigninIcon: {
    width: "6rem",
    height: "6rem",
    fontSize: "100%",
    fill: "#003e96",
  },
  dialogMainContent: {
    textAlign: "center",
    width: "24rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  marginForForms: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
