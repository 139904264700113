import React, { useState, useEffect } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import TrackingSlider from "@material-ui/core/Slider"

import {
  Grid,
  Paper,
  Container,
  Typography,
  makeStyles,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  useMediaQuery,
} from "@material-ui/core"

import box from "../../static/images/Icons/box.svg"
import Done from "../../static/images/Icons/done.svg"
// import UsdIcon from "../../static/images/Icons/usd.svg"
// import TryIcon from "../../static/images/Icons/try.svg"
import phone from "../../static/images/Icons/phone.svg"
import boxBlue from "../../static/images/Icons/box-blue.svg"
import phoneBlue from "../../static/images/Icons/phone-blue.svg"
import PostOffice from "../../static/images/Icons/post-office.svg"
import DeliveryTruck from "../../static/images/Icons/delivery-truck.svg"
import PostOfficeBlue from "../../static/images/Icons/post-office-blue.svg"
import DeliveryTruckBlue from "../../static/images/Icons/delivery-truck-blue.svg"

const windowGlobal = typeof window !== "undefined" && window

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  trackingSlider: {
    height: 34,
    marginTop: "-16px",
    width: "97%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-40px",
    },
    "& .MuiSlider-rail": {
      height: 34,
      color: "#e5e5e5",
      opacity: 1,
      borderRadius: 30,
      left: "51.6%",
      transform: "translateX(-50%)",
      width: "108%",
    },
    "& .MuiSlider-track": {
      marginTop: "1rem",
      marginLeft: 9,
    },
    "& .MuiSlider-thumb": {
      width: 20,
      height: 20,
      color: "#8cc63f",
      marginTop: 6,
      marginLeft: 0,
    },
    "& .MuiSlider-mark": {
      width: 20,
      height: 20,
      color: "#b7b7b7",
      marginTop: 6,
      borderRadius: 50,
      opacity: 1,
      backgroundColor: "#b7b7b7",
    },
    "& .MuiSlider-mark.MuiSlider-markActive": {
      backgroundColor: "#8cc63f",
    },
    "& .MuiSlider-markLabel": {
      maxWidth: 48,
    },
    "& .MuiSlider-markLabel img": {
      maxWidth: 48,
    },
  },
  trackingSliderContainer: {
    height: 34,
    color: "#e5e5e5",
    opacity: 1,
    borderRadius: 30,
    marginRight: "-17px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  trackingSliderImages: {
    width: "97%",
    height: 50,
    marginTop: "-37px",
    marginLeft: "-19px",
    marginBottom: 50,
    "& .MuiSlider-rail": {
      height: 50,
      color: "transparent",
      opacity: 1,
      borderRadius: 30,
      left: "51.6%",
      transform: "translateX(-50%)",
      width: "108%",
    },
    "& .MuiSlider-track": {
      display: "none",
    },
    "& .MuiSlider-thumb": {
      width: 24,
      height: 24,
      color: "transparent",
      backgroundImage: `url(${Done})`,
      marginLeft: "-2px",
      marginTop: "-8px",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
    "& .MuiSlider-mark": {
      width: 56,
      height: 56,
      color: "transparent",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      backgroundSize: "contain",
      [theme.breakpoints.down("sm")]: {
        width: 36,
        height: 36,
      },
    },
    "& .MuiSlider-markLabel": {
      maxWidth: 48,
    },
    "& .MuiSlider-markLabel img": {
      maxWidth: 48,
    },
    "& .MuiSlider-mark[data-index='0']": {
      backgroundImage: `url(${phone})`,
    },
    "& .MuiSlider-mark[data-index='2']": {
      backgroundImage: `url(${PostOffice})`,
    },
    "& .MuiSlider-mark[data-index='1'], .MuiSlider-mark[data-index='3']": {
      backgroundImage: `url(${DeliveryTruck})`,
    },
    "& .MuiSlider-mark[data-index='4']": {
      backgroundImage: `url(${box})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='0']": {
      backgroundImage: `url(${phoneBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='2']": {
      backgroundImage: `url(${PostOfficeBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='1'], .MuiSlider-mark.MuiSlider-markActive[data-index='3']": {
      backgroundImage: `url(${DeliveryTruckBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='4']": {
      backgroundImage: `url(${boxBlue})`,
    },
  },
  trackingSliderContainerImages: {
    height: 50,
    opacity: 1,
    borderRadius: 30,
    marginRight: "-17px",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 28,
      marginRight: 20,
    },
  },
  textColorBlue: {
    color: "#018fff",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {},
  },
  trackOrderTableFooter: {
    "& .MuiTableCell-footer": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.875rem",
    },
    "& .MuiTableCell-alignLeft": {
      fontWeight: "600",
    },
  },
  trackOrderTableHead: {
    "& .MuiTableCell-head": {
      fontWeight: "600",
    },
  },
  currencyIcon: {
    width: 14,
    marginBottom: "-2px",
  },
  slidersText: {
    textAlign: "right",
    fontSize: 20,
    marginTop: 12,
  },
}))

const Order = ({ orderId, orderStatus }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const [slidersValue, setSlidersValue] = useState(10)
  const [slidersText, setSlidersText] = useState("")
  const [orderData, setOrderData] = useState()
  const [currencyRateState, setCurrencyRate] = useState()

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
        },
      })
      .then(res => {
        setOrderData(res.data)
      })
  }, [orderStatus, i18n, jwtForProfile, orderId])

  useEffect(() => {
    if (orderStatus === "orderReceived") {
      return (setSlidersValue(10), setSlidersText(t("orders.orderReceived")))
    } else if (orderStatus === "pickupRequested") {
      return (setSlidersValue(30), setSlidersText(t("orders.pickupRequested")))
    } else if (orderStatus === "orderInPostHouse") {
      return (setSlidersValue(50), setSlidersText(t("orders.orderInPostHouse")))
    } else if (orderStatus === "orderGoToClient") {
      return (setSlidersValue(70), setSlidersText(t("orders.orderGoToClient")))
    } else if (
      orderStatus === "orderDelivered" ||
      orderStatus === "completed"
    ) {
      return (setSlidersValue(90), setSlidersText(t("orders.orderDelivered")))
    } else {
      return (setSlidersValue(10), setSlidersText(t("orders.orderReceived")))
    }
  }, [orderStatus])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const useStylesSlider = makeStyles(theme => ({
    sliderStyles: {
      "& .MuiSlider-thumb": {
        position: "relative",
        color: "transparent",
      },
      "& .MuiSlider-thumb::after": {
        content: `"${slidersText}"`,
        color: "#000",
        position: "absolute",
        width: 150,
        textAlign: "center",
        margin: "0 auto",
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          width: 100,
        },
      },
      width: "91%",
      color: "transparent !important",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-40px",
        marginBottom: 10,
        top: 22,
        width: "74%",
      },
      "& .MuiSlider-rail": {
        display: "none",
        color: "transparent",
      },
      "& .MuiSlider-track": {
        display: "none",
      },
      "& .MuiSlider-mark": {
        width: 20,
        height: 20,
        color: "#b7b7b7",
        marginTop: 6,
        borderRadius: 50,
        opacity: 1,
        backgroundColor: "#b7b7b7",
        display: "none",
      },
      "& .MuiSlider-markLabel": {
        maxWidth: 48,
      },
      "& .MuiSlider-markLabel img": {
        maxWidth: 48,
      },
    },
  }))

  const stylesSlider = useStylesSlider()

  const isMobile = useMediaQuery("(max-width:959.95px)")

  let sliderSteps

  if (isMobile) {
    sliderSteps = 93
  } else {
    sliderSteps = 90
  }

  return (
    <>
      <Container className={`${classes.fullWidth}`}>
        <Typography
          variant="h5"
          component="p"
          className={classes.textColorBlue}
        >
          {t("myProfile.shipmentMoves")}
        </Typography>
        <div className={classes.trackingSliderContainerImages}>
          <TrackingSlider
            className={`${classes.trackingSliderImages} trackingOrderCar`}
            value={slidersValue}
            step={20}
            marks
            min={10}
            max={90}
            disabled
          />
        </div>
        <div className={classes.trackingSliderContainer}>
          <TrackingSlider
            className={`${classes.trackingSlider}`}
            value={slidersValue}
            step={20}
            marks
            min={10}
            max={sliderSteps}
            disabled
            ThumbComponent="div"
          />
          <TrackingSlider
            className={`${stylesSlider.sliderStyles}`}
            value={slidersValue}
            step={20}
            marks
            min={10}
            max={90}
            disabled
            ThumbComponent="div"
          />
        </div>
        <br />
        <br />
        <br />
        <Typography
          variant="h5"
          component="p"
          className={classes.textColorBlue}
        >
          {t("myProfile.shipmentDetails")}
        </Typography>
        <br />
        <Grid container>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead className={classes.trackOrderTableHead}>
                <TableRow>
                  <TableCell>{t("myProfile.bookName")}</TableCell>
                  <TableCell align="center">{t("myProfile.count")}</TableCell>
                  <TableCell align="center">{t("myProfile.price")}</TableCell>
                  <TableCell align="center">
                    {t("myProfile.discount")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData &&
                  orderData.order_items.map(item => (
                    <TableRow key={item.book.id}>
                      <TableCell>{item.book.title}</TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">
                        {item.price && currencyRateState
                          ? (item.price * currencyRateState).toFixed(2)
                          : item && item.price}
                        {item && item.price ? (
                          currencyRateState > 1 ? (
                            <>&#8378;</>
                          ) : (
                            <>&#36;</>
                          )
                        ) : (
                          " "
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item && item.discountPrice
                          ? (item.discountPrice * currencyRateState).toFixed(2)
                          : item && item.discountPrice}
                        {item && item.discountPrice ? (
                          currencyRateState > 1 ? (
                            <>&#8378;</>
                          ) : (
                            <>&#36;</>
                          )
                        ) : (
                          " "
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter className={classes.trackOrderTableFooter}>
                <TableRow>
                  <TableCell rowSpan={4} />
                  <TableCell align="left" colSpan={2}>
                    {t("myProfile.subtotal")}
                  </TableCell>
                  <TableCell align="center">
                    {orderData && currencyRateState
                      ? (orderData.subTotal * currencyRateState).toFixed(2)
                      : orderData && orderData.subTotal}
                    {orderData && orderData.subTotal ? (
                      currencyRateState > 1 ? (
                        <>&#8378;</>
                      ) : (
                        <>&#36;</>
                      )
                    ) : (
                      " "
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    {t("myProfile.discountedPrice")}
                  </TableCell>
                  <TableCell align="center">
                    {orderData && currencyRateState
                      ? (orderData.discountedPrice * currencyRateState).toFixed(
                          2
                        )
                      : orderData && orderData.discountedPrice}
                    {orderData && orderData.discountedPrice ? (
                      currencyRateState > 1 ? (
                        <>&#8378;</>
                      ) : (
                        <>&#36;</>
                      )
                    ) : (
                      " "
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    {t("myProfile.shippingCost")}
                  </TableCell>
                  <TableCell align="center">
                    {orderData && currencyRateState
                      ? (orderData.shippingCost * currencyRateState).toFixed(2)
                      : orderData && orderData.shippingCost}
                    {orderData && orderData.shippingCost ? (
                      currencyRateState > 1 ? (
                        <>&#8378;</>
                      ) : (
                        <>&#36;</>
                      )
                    ) : (
                      " "
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    {t("myProfile.total")}
                  </TableCell>
                  <TableCell align="center">
                    {orderData && currencyRateState
                      ? (orderData.total * currencyRateState).toFixed(2)
                      : orderData && orderData.total}
                    {orderData && orderData.total ? (
                      currencyRateState > 1 ? (
                        <>&#8378;</>
                      ) : (
                        <>&#36;</>
                      )
                    ) : (
                      " "
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  )
}
export default Order
